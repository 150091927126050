<template>
	<div>
		<div class="box box_mm">
			<div class="box_main">
				<div
					style="height: 70vh;overflow-y: auto;padding-right: 0.5rem;padding-bottom: 40px;box-sizing: border-box;">
					<el-form :model="addnewinfo" :rules="addnewinfo_rules" ref="addnewinfo" label-width="100px"
						label-position="left" class="demo-ruleForm" style="padding-left: 20px;">
						<el-form-item label="标题" prop="name">
							<el-input v-model="addnewinfo.name" style="width: 60%;" placeholder="标题"></el-input>
						</el-form-item>
						<el-form-item label="内容" prop="content" style="display: flex;align-items: flex-start;">
							<div style="border: 1px solid #ccc;width: 60vw;">
								<Toolbar style="border-bottom: 1px solid #ccc;" :editor="editor"
									:defaultConfig="toolbarConfig" mode="default" />
								<Editor style="height: 500px; overflow-y: hidden;font-size: 16px;"
									v-model="addnewinfo.content" :defaultConfig="editorConfig" mode="default"
									@onCreated="onCreated" />
							</div>
						</el-form-item>
						
						<el-form-item label="所属会议" prop="wymeeting_id">
							<el-select v-model="addnewinfo.wymeeting_id" placeholder="请选择所属会议" style="width:220px;"
								clearable>
								<el-option :label="item.name" :value="item.id" v-for="(item,index) in sshylist"
									:key="index"></el-option>
							</el-select>
						</el-form-item>
						
						<el-form-item label="截止时间" required>
							<el-date-picker
							      v-model="addnewinfo.endtime"
							      type="datetime"
								  value-format="yyyy-MM-dd HH:mm:ss"
							      placeholder="选择日期时间">
							</el-date-picker>
						</el-form-item>
						
						<el-form-item label="接收人" required>
							<div style="display: flex;align-items: center;">
								<el-button type="primary" size="small" @click="select">选择</el-button>								
							</div>
							<div>
								<span style="color: #999;">已选人员：</span>{{user_name}}
							</div>
						</el-form-item>

						<el-form-item label="上传附件" style="display: flex;align-items: center;">
							<el-upload
							  class="upload-demo"
							  :action="baseurl+'/api/login/upload'" :headers="{
							  		token:$store.state.userinfo.token
							  	}"
							  :on-success="handleAvatarSuccess"
							  :on-remove="handleChange"
							  :file-list="fileList">
							  <el-button size="small" type="primary">点击上传</el-button>
							</el-upload>
						</el-form-item>
					</el-form>

				</div>

				<div style="display: flex;align-content: center;margin-top: 30px;">
					<el-button @click="setaddnew">取 消</el-button>
					<el-button type="primary" @click="submitForm">修 改</el-button>
				</div>
			</div>
		</div>
		
		
		
		<selectusers ref="myselectuser" @selectedry="selectedry"></selectusers>
	</div>

</template>

<script>
	import {
		Message
	} from 'element-ui'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	import selectusers from '@/components/select_users.vue'
	export default {
		components: {
			Editor,
			Toolbar,
			selectusers
		},
		data() {
			return {				
				baseurl: "",
				addnewinfo: {
					name:'',
					content:'',
					endtime:'',
					jsruser_id:'',
					fujian:'',
					wymeeting_id:'',//所属会议
				},
				addnewinfo_rules: {
					name: [{
						required: true,
						message: '请输入标题',
						trigger: 'blur'
					}],
					content: [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
					wymeeting_id: [{
						required: true,
						message: '请选择所属会议',
						trigger: 'change'
					}],
				},
				id: '',
				user_id: '',
				user_name: '',
				fileList:[],
				sshylist:[],
				
				//富文本相关
				editor: null,
				toolbarConfig: {},
				editorConfig: {
					placeholder: '请输入内容...',
					MENU_CONF: {
						uploadImage: {
							server: this.$URL + '/api/login/upload',
							fieldName: 'file',
							// 单个文件的最大体积限制，默认为 2M
							maximgSize: 10 * 1024 * 1024, // 10M
							// 最多可上传几个文件，默认为 100
							maxNumberOfimgs: 100,
							// 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
							allowedimgTypes: [],
							// 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
							meta: {
				
								// otherKey: 'yyy'
								// img:''
							},
							// 将 meta 拼接到 url 参数中，默认 false
							metaWithUrl: false,
				
							// 自定义增加 http  header
							headers: {
								token: this.$store.state.userinfo.token
								// otherKey: 'xxx'
							},
				
							// 跨域是否传递 cookie ，默认为 false
							withCredentials: false,
				
							// 超时时间，默认为 10 秒
							timeout: 10 * 1000, //10 秒
				
							// 上传前
							onBeforeUpload(imgs) {
								Message({
									message: '图片正在上传中,请耐心等待',
									grouping: true,
									duration: 0,
									customClass: 'uploadTip',
									iconClass: 'el-icon-loading',
									showClose: true
								});
								return imgs;
							},
							// 自定义插入图片
							customInsert(res, insertFn) {
								// 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
								// 先关闭等待的ElMessage
								Message.closeAll();
				
								if (res.code === 200) {
									Message.success({
										message: "图片上传成功",
										grouping: true,
									});
								} else {
									Message.error({
										message: "图片上传失败，请重新尝试",
										grouping: true,
									});
								}
								// 从 res 中找到 url alt href ，然后插入图片
								insertFn(res.fullurl);
								// console.log(res, "res.data")
							},
				
							// 单个文件上传成功之后
							onSuccess(img, res) {
								console.log(`${img.name} 上传成功`, res);
							},
				
							// 单个文件上传失败
							onFailed(img, res) {
								console.log(`${img.name} 上传失败`, res);
							},
				
							// 上传进度的回调函数
							onProgress(progress) {
								console.log('progress', progress);
								// progress 是 0-100 的数字
							},
				
							// 上传错误，或者触发 timeout 超时
							onError(img, err, res) {
								console.log(`${img.name} 上传出错`, err, res);
							}
						},
				
						// 上传视频
						uploadVideo: {
							server: this.$URL + '/api/login/upload',
							fieldName: "file",
							// 单个文件的最大体积限制，默认为 10M
							maxFileSize: 500 * 1024 * 1024, // 500M
							// 最多可上传几个文件，默认为 5
							maxNumberOfFiles: 3,
							// 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
							allowedFileTypes: ["video/*"],							
							// 将 meta 拼接到 url 参数中，默认 false
							metaWithUrl: false,
							// 自定义增加 http  header
							headers: {
								token: this.$store.state.userinfo.token
								// otherKey: 'xxx'
							},
							// 跨域是否传递 cookie ，默认为 false
							withCredentials: false,
							// 超时时间，默认为 30 秒
							timeout: 30 * 1000, // 15 秒
							onBeforeUpload(imgs) {
								Message({
									message: '正在上传中,请耐心等待',
									grouping: true,
									duration: 0,
									customClass: 'uploadTip',
									iconClass: 'el-icon-loading',
									showClose: true
								});
								return imgs;
							},
							// 自定义插入图片
							customInsert(res, insertFn) {
								// 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
								// 先关闭等待的ElMessage
								Message.closeAll();
							
								if (res.code === 200) {
									Message.success({
										message: "视频上传成功",
										grouping: true,
									});
								} else {
									Message.error({
										message: "上传失败，请重新尝试",
										grouping: true,
									});
								}
								// 从 res 中找到 url alt href ，然后插入图片
								insertFn(res.fullurl);
								// console.log(res, "res.data")
							},
							// 上传进度的回调函数
							onProgress(progress) {
								console.log("progress", progress);
							},
							// 单个文件上传成功之后
							onSuccess(file, res) {
								console.log(`${file.name} 上传成功`, res);
							},
							// 单个文件上传失败
							onFailed(file, res) {
								console.log(`${file.name} 上传失败`, res);
							},
							// 上传错误，或者触发 timeout 超时
							onError(file, err, res) {
								console.log(`${file.name} 上传出错`, err, res);
							}
						}
					}
				},
			}
		},
		created() {
			this.id = this.$route.query.id
			this.baseurl = this.$URL
			
			this.$post({//所属会议列表
				url: '/api/invest_research_wymeeting/index',
				params: {
					type:1,
					qxtype:1,
					p: 1,
					size: 999
				}
			}).then((res) => {		
				this.sshylist = res.list
			})
			
			
			this.$post({//获取详情
				url: '/api/invest_research_topics/details',
				params: {
					id:this.id
				}
			}).then((res) => {		
				this.addnewinfo = res
				this.user_id = res.jsruser_id.map((item)=>{ return item.id}).join(',')
				this.user_name = res.jsruser_id.map((item)=>{ return item.name}).join(',')
				if(res.fujian.length>0){
					res.fujian.forEach((item,index)=>{
						let obj ={
							name:item.filename,
							url:item.fullurl,
							id:item.id
						}
						this.fileList.push(obj)
					})
				}
			})
		},
		methods: {
			select(){
				let cxidlist = this.user_id?this.user_id.split(','):[]
				let cxnamelist = this.user_name?this.user_name.split(','):[]
				let list = []
				if(cxidlist.length>0){
					cxidlist.forEach((item,index)=>{
						let obj={
							name:cxnamelist[index],
							id:Number(item)
						}
						list.push(obj)
					})
				}				
				this.$refs.myselectuser.selectuser = list.length>0?list:[]
				this.$refs.myselectuser.dialogVisible = true
			},
			selectedry(e){
				this.user_id = e.map((item)=>{ return item.id })
				this.user_id = this.user_id.join(',')
				this.user_name = e.map((item)=>{ return item.name })
				this.user_name = this.user_name.join(',')
			},
			onCreated(editor) {
				this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
			},
			submitForm() {
				if (!this.addnewinfo.endtime) {
					this.$message.warning('请选择截止时间')
					return
				}
				if (!this.user_name) {
					this.$message.warning('请选择接收人员')
					return
				}
				delete this.addnewinfo.addtime
				this.$refs.addnewinfo.validate((valid) => {
					if (valid) {
						this.addnewinfo.jsruser_id = this.user_id
						if(this.fileList.length>0){
							let fj_ids = this.fileList.map(item=>{return item.id})
							this.addnewinfo.fujian = fj_ids.join(',')
						}
						this.$post({
							url: '/api/invest_research_topics/edit',
							params: this.addnewinfo
						}).then((res) => {
							this.$message.success('修改成功')
							this.$router.push('/system/dy_talk_list')
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				})
			},
			handleAvatarSuccess(res, file) {
				let obj ={
					name:res.file_name,
					url:res.fullurl,
					id:res.id
				}
				this.fileList.push(obj)
			},
			handleChange(file, fileList) {
				this.fileList = fileList			    
			},
			setaddnew() {
				this.$router.push('/system/dy_talk_list')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 55px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.5rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table {
		margin-top: 10px;
		border-left: none !important;
	}

	::v-deep .el-table__fixed {
		border-bottom: none;
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	::v-deep .el-form-item__content {
		display: flex;
		flex-direction: column;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}

	::v-deep .el-table__fixed-right::before {
		background-color: #fff !important;
	}

	.item_class {
		display: flex;
		align-items: center;
		width: 100%;
		box-sizing: border-box;

		.el-form-item {
			margin-right: 150px;
		}
	}

	::v-deep.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>